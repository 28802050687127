const pristine = {
    unit: {
        base: "40px",
        header: "100px",
        headerMobile: "70px",
    },
    gap: {
        base: '64px',
    },
    outerSpacing: {
        wordSpacing: '4px',
        xs: "10px",
        sm: "15px",
        base: "24px",
        lg: '30px',
        xl: '40px',
        '2xl': '100px',
        '3xl': '150px',
    },
    temp: {
        colors: {
            'primary': '#F08116',
            'secondary': '#1D2329',
            'tertiary': '#FDE6CD',
            'black': '#1D2329',
            'white': '#FFFFFF',
            'red': '#B50707',
            'grey-light': '#E6E6E6',
            'grey': '#D5D5D5',
            'beige': '#FDE6CD'
        },
        width: {
            button: {
                'base': '250px'
            }
        },
        height: {
            button: {
                'base': '50px'
            }
        },
        padding: {
            cards: {
                'base-x': '50px',
                'base-y': '30px',
            },
            section: {
                'base-x': '140px',
                'base-y': '90px',
            },
            button: {
                'base-x': '50px',
                'base-y': '10px'
            },
        },
        fontSizes: {
            'xxs': '11px',
            'xs': '14px',
            'sm': '16px',
            'base': '18px',
            'lg': '22px',
            'xl': '35px',
            '2xl': '70px',
            '3xl': '95px',
            '4xl': '110px'
        },
        borderRadius: {
            'circle': '50%'
        },
        screens: {
        }
    }
};
const theme = {
    pristine
};

module.exports = theme;
