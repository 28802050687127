(() => {
  let elements = Array.from(document.querySelectorAll('.useCaseWrapper'));
  if (elements.length > 0) {
    const setpolygon = () => {
      elements.forEach((element: any) => {
        let svg = element.querySelector('svg');
        let paths = Array.from(svg.querySelectorAll('path'));
        (paths as any).forEach((path: any, index: number) => {
          if (index === 0) {
            path.setAttribute('d', `M0,0 ${svg.clientWidth},0 ${svg.clientWidth - 80.5},80.5 0,80.5z`);
          } else if (index === 1) {
            path.setAttribute('d', `M1,0 ${svg.clientWidth - 1},0 ${svg.clientWidth - 80.5},79 1,79z`);
          }
        });
      });
    };

    window.addEventListener('resize', (e: any) => {
      setpolygon();
    });
  }
})();
