// import '@babel/polyfill';
import '@/polyfill/forEach';
import '@/polyfill/isNan';

import Vue from 'vue';
import SvgMasks from '@/components/SvgMasks.vue';
import HomeSlider from '@/components/HomeSlider.vue';
import AboutSlider from '@/components/AboutSlider.vue';
import '@/plugins/element.js';
import '@/js/assets';
import '@/js/menu';
import '@/js/card-slider';
import '@/js/parallax';
import '@/js/inputs';
import '@/js/gallery';
import '@/js/object-fit';
import '@/js/use-case-wrapper';

const styleguideJSON = require('@/styleguide.json');
const Styleguide = require('@bit/wurielle.pristine.vue-components.dss-styleguide').default;

Vue.component('vue-styleguide', Styleguide);
Vue.config.productionTip = false;

if (document.querySelector('#styleguide')) {
    new Vue({
        components: {
            Styleguide,
        },
        data() {
            return {
                styleguideJSON,
            };
        },
        template: `
          <Styleguide :styleguide="styleguideJSON"></Styleguide>`,
    }).$mount('#styleguide');
}

if (document.querySelector('#js-svg-masks')) {
    new Vue({
        render: (h) => h(SvgMasks),
    }).$mount('#js-svg-masks');
}

if (document.querySelector('#js-home-slider')) {
    new Vue({
        components: {
            'home-slider': HomeSlider,
        },
        // render: (h) => h(HomeSlider),
    }).$mount('#js-home-slider');
}

if (document.querySelector('#js-about-slider')) {
    new Vue({
        components: {
            'about-slider': AboutSlider,
        },
    }).$mount('#js-about-slider');
}

// Element Queries
const ElementQueries = require('css-element-queries/src/ElementQueries');
ElementQueries.listen();

if (document.querySelector('[data-on-load-scroll]')) {
    const el = document.querySelector('[data-on-load-scroll]');
    window.scrollTo(0, window.pageYOffset + (el as HTMLElement).getBoundingClientRect().top - (document.querySelector('.header') as HTMLElement).offsetHeight);
}
