




























import {
    Vue,
    Component,
} from 'vue-property-decorator';
const theme = require('@config/theme');
@Component
export default class SvgMasks extends Vue {
    bb: any = (document.querySelector('body') as HTMLElement).getBoundingClientRect();
    vh: number = document.documentElement.clientHeight;
    vw: number = document.documentElement.clientWidth;
    slicesPerScreen: number = 3;
    mounted() {
        this.setBB();
        window.addEventListener('resize', this.setBB);
        (() => {
            setTimeout(() => {
                const evt = window.document.createEvent('UIEvents');
                (evt as any).initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            }, 500);
        })();
    }

    setBB() {
        this.bb = (document.querySelector('body') as HTMLElement).getBoundingClientRect();
        this.vh = document.documentElement.clientHeight;
        this.vw = document.documentElement.clientWidth;
    }

    getPos1(index: any) {
        return this.maskDistance * index + this.initialStart;
    }

    getPos2(index: any) {
        return this.maskDistance * index + this.initialStart + this.maskDistance;
    }

    linePos(index: any) {
        return this.maskDistance * index + this.initialStart + this.headerHeight;
    }

    get bodyWidth() {
        return this.bb.width;
    }

    get bodyHeight() {
        return this.bb.height;
    }

    get maskCount() {
        if (this.bb.height && this.maskDistance) {
            return Math.ceil(this.bb.height / this.maskDistance) + this.slicesPerScreen;
        }
        return 0;
    }

    get maskDistance() {
        /* the largest size is base on the viewport but if we want to abuse the design we could set a custom w/h
         * for instance is we want the diagonals to fit the homepage's slider height
         */
        const largestSide = this.vw  > this.vh  ? this.vw  : this.vh;
        return largestSide / 3;
    }

    get initialStart() {
        const shortestSide = this.vw  < this.vh  ? this.vw  : this.vh;
        return - this.maskDistance + shortestSide/2 - this.headerHeight / 2
            ;
    }

    get headerHeight() {
        return Number(theme.pristine.unit.header.replace('px', ''));
    }

    get theme() {
        return theme;
    }
}
