(() => {
    const menuShow = document.querySelector('#js-show-menu');
    const menuHide = document.querySelector('#js-hide-menu');

    if(menuShow) {
        (menuShow as any).addEventListener('click', () => {
            (document.querySelector('.menu') as any).classList.toggle('menu-is-open');
            // (document.querySelector('.header')as any).classList.toggle('filter');
            // (document.querySelector('.popin')as any).classList.toggle('filter');
            // (document.querySelector('.body')as any).classList.toggle('filter');
            // (document.querySelector('.footer')as any).classList.toggle('filter');
        });
    } 
    
    if(menuHide) {
        (menuHide as any).addEventListener('click', () => {
            (document.querySelector('.menu') as any).classList.toggle('menu-is-open');
            // (document.querySelector('.header')as any).classList.toggle('filter');
            // (document.querySelector('.popin')as any).classList.toggle('filter');
            // (document.querySelector('.body')as any).classList.toggle('filter');
            // (document.querySelector('.footer')as any).classList.toggle('filter');
        });
    }
})();
