import {Swiper, Navigation} from 'swiper';
// @ts-ignore
Swiper.use(Navigation);

export default class CardSlider {
    private id: any = null;
    private instance: any = null;
    constructor(elementOrSelector: any, options = {}) {
        if (!elementOrSelector) throw new Error('Card Slider requires a Selector or a HTML Element.');
        // if (typeof elementOrSelector === 'string' && (document as any).querySelector(elementOrSelector).dataset) {
        //     this.id = (document as any).querySelector(elementOrSelector).dataset.id;
        // } else if ((document as any).querySelector(elementOrSelector).dataset) {
        //     this.id = (document as any).querySelector(elementOrSelector).dataset.id;
        // }
        this.instanciate(elementOrSelector, options);
    }

    private instanciate(elementOrSelector: any, options: any) {
        this.instance = new Swiper(elementOrSelector, {
           ...options,
        });
        let {instance} = this;
        let itemPerView = instance.params.breakpoints ? instance.params.breakpoints[instance.currentBreakpoint].slidesPerView : instance.params.slidesPerView;
        if (instance && instance.slides.length <= itemPerView) {
           instance.navigation.$nextEl[0].classList.add('pr-hidden');
           instance.navigation.$prevEl[0].classList.add('pr-hidden');
        }
    }
}
[].forEach.call(document.querySelectorAll('.js-card-slider'),(element: HTMLElement) => {
    const id = element.getAttribute('data-id') || null;
    const option = element.getAttribute('data-option') ? JSON.parse((element.getAttribute('data-option') as any)) : {};
    let slider = new CardSlider(element, {
        navigation: {
            prevEl: document.querySelector(`[data-prev="${id}"]`),
            nextEl: document.querySelector(`[data-next="${id}"]`)
        },
        slidesPerView: 'auto',
        spaceBetween: 24,
        ...option,
    });
});
