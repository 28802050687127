import { render, staticRenderFns } from "./AboutSlider.vue?vue&type=template&id=0882a136&scoped=true&"
import script from "./AboutSlider.vue?vue&type=script&lang=ts&"
export * from "./AboutSlider.vue?vue&type=script&lang=ts&"
import style0 from "./AboutSlider.vue?vue&type=style&index=0&id=0882a136&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0882a136",
  null
  
)

export default component.exports