const elements = document.querySelectorAll('.custom-file');

[].forEach.call(elements, (element: HTMLElement) => {
    const input = element.querySelector('input[type="file"]');
    if (input) {
        input.addEventListener('change', () => {
            const filename = element.querySelector('.filename');
            if (filename) {
                filename.innerHTML = `<div class="filename pr-mb-xs pr-max-w-full pr-overflow-hidden" style="text-overflow: ellipsis; " title="${(input as any).value.split('\\').pop()}">${(input as any).value.split('\\').pop()}</div>`;
            } else {
                const filenameNode = document.createElement('div');
                filenameNode.setAttribute('class', 'filename');
                filenameNode.innerHTML = `<div class="filename pr-mb-xs pr-max-w-full pr-overflow-hidden" style="text-overflow: ellipsis; " title="${(input as any).value.split('\\').pop()}">${(input as any).value.split('\\').pop()}</div>`;
                element.appendChild(filenameNode);
            }
        });
    }
});
