import {Swiper, Thumbs} from 'swiper';

Swiper.use([Thumbs]);

let galleries: any = '[data-ref="gallery"]';

document.querySelectorAll(galleries).forEach((element: any) => {
  let main = element.querySelector('[data-ref="main"]');
  let thumb = element.querySelector('[data-ref="thumb"]');

  let mainConfig = main.dataset.config;
  let thumbConfig = main.dataset.config;

  let thumbGallery = new Swiper(thumb, {
    spaceBetween: 24,
    slidesPerView: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    ...thumbConfig,
    breakpoints: {
      998: {
        slidesPerView: 3,
      },
    },
  });

  let mainGallery = new Swiper(main, {
    slidesPerView: 1,
    spaceBetween: 10,
    thumbs: {
      swiper: thumb ? thumbGallery : null,
    },
    ...mainConfig,
  });
});
