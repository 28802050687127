









































































































































































































































































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import axios from 'axios';
import { TweenLite, Expo } from 'gsap';
import { find } from 'lodash';

@Component
export default class HomeSlider extends Vue {
  @Prop({ default: 5000 }) private timeout!: number;
  @Prop({ default: 'fr' }) private locale!: string;
  private interval: any = null;
  private currentSlide: number = 0;
  private vw: any = null;
  private vh: any = null;
  private slides: any[] = [];

  private pad(value: number, size: number = 2) {
    let s = String(value);
    while (s.length < (size || 2)) {
      s = '0' + s;
    }
    return s;
  }

  private goToPrev() {
    if (this.slides[this.currentSlide - 1]) {
      this.currentSlide = this.currentSlide - 1 < 0 ? this.slides.length - 1 : (this.currentSlide - 1) % this.slides.length;
    }
  }

  private goToNext() {
    if (this.slides[this.currentSlide + 1]) {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    }
  }

  private goTo(index: number) {
    this.currentSlide = index;
    this.resetInterval();
  }

  private get nextSlide() {
    if (this.slides[this.currentSlide + 1]) {
      return this.slides[(this.currentSlide + 1) % this.slides.length];
    }
    return null;
  }

  private transitionLeave(el: any, done: any) {
    done();
  }

  private transitionEnter(el: any, done: any) {
    done();
  }

  private transitionIndexLeave(el: any, done: any) {
    const translateValue = 25;
    TweenLite.to(el, 1, {
      x: -translateValue,
      y: translateValue,
      // scale: 2,
      opacity: 0,
      ease: Expo.easeIn,
      onComplete: done,
    });
  }

  private transitionIndexEnter(el: any, done: any) {
    const translateValue = 25;
    TweenLite.set(el, {
      x: translateValue,
      y: -translateValue,
      // scale: .5,
      opacity: 0,
    });
    TweenLite.to(el, 1, {
      x: '0%',
      y: '0%',
      // scale: 1,
      opacity: 1,
      ease: Expo.easeOut,
      onComplete: done,
    });
  }

  private transitionLeftDateLeave(el: any, done: any) {
    const translateValue = this.vh / 6;
    TweenLite.to(el, 1, {
      x: -translateValue,
      y: translateValue,
      // scale: 2,
      opacity: 0,
      ease: Expo.easeIn,
      onComplete: done,
    });
  }

  private transitionLeftDateEnter(el: any, done: any) {
    const translateValue = this.vh / 6;
    TweenLite.set(el, {
      x: translateValue,
      y: -translateValue,
      // scale: .5,
      opacity: 0,
    });
    TweenLite.to(el, 1, {
      x: '0%',
      y: '0%',
      // scale: 1,
      opacity: 1,
      ease: Expo.easeOut,
      onComplete: done,
    });
  }

  private transitionCenterDateLeave(el: any, done: any) {
    const date = el.querySelector('.card-picture__date');
    const translateValue = this.vw;
    TweenLite.to(date, .8, {
      x: -translateValue,
      ease: Expo.easeIn,
    });
    TweenLite.to(el, 1, {
      x: -translateValue,
      ease: Expo.easeIn,
      onComplete: done,
    });
  }

  private transitionCenterDateEnter(el: any, done: any) {
    const date = el.querySelector('.card-picture__date');
    const translateValue = this.vw;
    TweenLite.set(el, {
      x: translateValue,
    });
    TweenLite.set(date, {
      x: translateValue,
    });
    TweenLite.to(date, 1.75, {
      x: 0,
      ease: Expo.easeOut,
    });
    TweenLite.to(el, 1, {
      x: '0',
      ease: Expo.easeOut,
      onComplete: done,
    });
  }

  private transitionRightDateLeave(el: any, done: any) {
    const translateValue = this.vh / 6;
    TweenLite.to(el, 1, {
      x: -translateValue,
      y: translateValue,
      delay: .2,
      // scale: 2,
      opacity: 0,
      ease: Expo.easeIn,
      onComplete: done,
    });
  }

  private transitionRightDateEnter(el: any, done: any) {
    const translateValue = this.vh / 6;
    TweenLite.set(el, {
      x: translateValue,
      y: -translateValue,
      // scale: .5,
      opacity: 0,
    });
    TweenLite.to(el, 1, {
      x: '0%',
      y: '0%',
      // scale: 1,
      opacity: 1,
      ease: Expo.easeOut,
      onComplete: done,
    });
  }

  private created() {
    axios.get('/keyevent/api')
      .then((response: any) => {
        this.slides = response.data.data;
      })
      .catch((err: any) => console.error(err));
  }

  private mounted() {
    this.setBB();
    window.addEventListener('resize', this.setBB);
    // this.createInterval();
  }

  setBB() {
    this.vh = document.documentElement.clientHeight;
    this.vw = document.documentElement.clientWidth;
  }

  private createInterval() {
    this.interval = setInterval(this.goToNext, this.timeout);
  }

  private resetInterval() {
    clearInterval(this.interval);
    this.createInterval();
  }
}
