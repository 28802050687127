












































































import {
    Vue,
    Component,
    Prop
} from 'vue-property-decorator';

import {TweenLite, Expo} from 'gsap';
import axios from 'axios';
@Component
export default class HomeSlider extends Vue {
    @Prop() private catchphrase!: string;
    @Prop() private ctaContent!: string;
    @Prop() private ctaHref!: string;
    @Prop({default: 5000}) private timeout!: number;
    private interval: any = null;
    private imageCollection: any = [];
    private transitionIndex: number = 0;
    private currentSlide: number = 0;

    private isEven(num: number) {
        return num % 2 == 0;
    }

    private goToPrev() {
        this.currentSlide = this.currentSlide - 1 < 0 ? 2 : (this.currentSlide - 1) % 3;
    }

    private goToNext() {
        this.currentSlide = (this.currentSlide + 1) % 3;
    }

    private goTo(index: number) {
        this.currentSlide = index;
        this.resetInterval();
    }

    private transitionLeave(el: number, done: any) {
        // console.log('leave', el, done);
        /*
         * DO NOT USE TRANSFORM
         * there is a silly bug that breaks animations inside clipPath Element because of the transform
         * and will-change attributes
         * go figure..
         */
        done();
    }

    private transitionEnter(el: number, done: any) {
        // console.log('enter', done);
        /*
         * DO NOT USE TRANSFORM
         * there is a silly bug that breaks animations inside clipPath Element because of the transform
         * and will-change attributes
         * go figure..
         */
        // console.log((el as any).parentNode.querySelectorAll('image'));
        const oldEl = [].find.call((el as any).parentNode.querySelectorAll('image'), (node: HTMLElement) => {
            return !this.hasClass(node, 'v-enter');
        });
        const index = Number((oldEl as any).getAttribute('data-index'));
        TweenLite.set(el, {attr: {x: '100%'}});
        TweenLite.to(oldEl, .5, {attr: {x: '0%'}});
        TweenLite.to(oldEl, 1, {attr: {x: '-50%'},
            delay:  index * .15,
            ease: Expo.easeOut});
        TweenLite.to(el, 1, {attr: {x: '0%'},
            delay: index *.15,
            ease: Expo.easeOut, onComplete: done});
        /*
         * There are issues when passing custom arguments in transitions so we do it manually here
         */
        this.transitionIndex = (this.transitionIndex + 1) % 3 ;
    }

    private created() {
        axios.get('/slider/api')
            .then((response: any) => {
                this.imageCollection = response.data;
                // console.log(this.imageCollection);
            })
            .catch((err: any) => console.log(err));
    }

    private mounted() {
        this.createInterval();
    }

    private createInterval() {
        this.interval = setInterval(this.goToNext, this.timeout);
    }

    private resetInterval() {
        clearInterval(this.interval);
        this.createInterval();
    }

    private hasClass(element: any, selector: any) {
        return (' ' + element.className + ' ').indexOf(' ' + selector + ' ') > -1;
    }
}
