import '@/styles/main.scss';

import '@/assets/use_case_discorver.png';
import '@/assets/laprecision-logo-meta.jpg';
import '@/assets/laprecision-logo.svg';
import '@/assets/laprecision-logo-black.svg';
import '@/assets/laprecision-logo-white.svg';
import '@/assets/laprecision-logo-p.svg';
import '@/assets/laprecision-logo-p-black.svg';
import '@/assets/laprecision-logo-text.svg';
import '@/assets/icn_avion.svg';
import '@/assets/icn_coeur.svg';
import '@/assets/icn_eclair.svg';
import '@/assets/icn_missile.svg';
import '@/assets/icn_robot.svg';
import '@/assets/burger_menu.svg';
import '@/assets/icn_epa.svg';
import '@/assets/icn_pin.svg';
import '@/assets/icn_pio.svg';
import '@/assets/icn_star.svg';
import '@/assets/cross_black.svg';
import '@/assets/cross_white.svg';
import '@/assets/airplane@2x.png';
import '@/assets/doctor@2x.png';
import '@/assets/eolienne@2x.png';
import '@/assets/robot@2x.png';
import '@/assets/soldier@2x.png';
import '@/assets/laprecision-logo-placeholder.png';
import '@/assets/laprecision-logo-p-placeholder.png';
import '@/assets/laprecision-placeholder.png';
import '@/assets/epanouissement@2x.png';
import '@/assets/excellence@2x.png';
import '@/assets/pionnier@2x.png';
import '@/assets/proximite@2x.png';
import '@/assets/ISO9001.png';
import '@/assets/EN9100.png';
import '@/assets/ISO13485.png';
import '@/assets/rd_author.png';
import '@/assets/favicon.png';

